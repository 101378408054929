import {
  BoxStyle,
  BoxYellowOutline,
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { InterviewPageBox } from "../InterviewStyled";
import sample from "assets/sample.mp4";
import JolPlayer from "jol-player";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { useNavigate, useParams } from "react-router";
import Cookies from "js-cookie";
import {
  GetSingleInterview,
  InterviewAnalysis,
  getAllVideos,
  GetStudentsDetails,
} from "../../../axios/apis";
import { css } from "@emotion/react";

// Define CSS for the spinner
const override = css`
  display: block;
  border-color: yellow;
  margin: 4px;
`;
const FinalReviewAns = ({
  handleShow,
  finalSubmit,
  video,
  recordedAnsno,
  setRecordedAnsNo,
  testvideo,
  questions,
  allVideosUploaded,
}) => {
  const params = useParams();
  const name = Cookies.get("name");
  const [loading, setLoading] = useState(false);
  const [questionss, setquestionss] = useState([]);
  const [Interview, setInterView] = useState(false);

  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      setquestionss(response.data.interview.interviewquestions);
      setInterView(response.data.interview);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetInterviewDetails();
    console.log("all uplaoded", allVideosUploaded);
  }, [params.id]);

  // const jobdetails = localStorage.getItem("jobDetails");
  // const jobDetails = JSON.parse(localStorage.getItem("jobDetails"));
  // const isCorporate = jobDetails?.creator_type === "corporate";
  // console.log(jobDetails);
  // console.log(jobdetails.creatortype);
  const isCorporate = Interview?.creator_type === "corporate";

  return (
    <>
      <Toaster />
      <style>
        {`
      .spinner {
        width: 10px !important;
        height: 10px !important;
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: yellow;
        animation: spin 1s ease-in-out infinite;
      }
      @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
      .spinner-container {
        display: flex;
        align-items: center;
      }
      .spinner-text {
        margin-left: 8px;
        color: yellow;
      }
    `}
      </style>
      <InterviewPageBox className=" px-3 submit-page">
        <div className="col-12">
          <div className="section-1 my-2 w-100 d-flex flex-column px-3">
            <BoxStyle className="d-flex justify-content-between box-flex">
              <div className="col-md-5 col-sm-12 col-12 d-flex">
                <div className="score-submit-box w-100 pe-2">
                  <BoxYellowOutline className="p-3 mb-4">
                    <h6>Hey! {name}</h6>
                    {/* <h6>Lets Check Your Video</h6> */}
                    <div className="d-flex justify-content-between mt-3">
                      {!isCorporate && (
                        <ButtonYellowOutline
                          width="120px"
                          className="h-40"
                          onClick={handleShow}
                        >
                          Record Again
                        </ButtonYellowOutline>
                      )}
                      <ButtonYellowFill
                        width="100px"
                        className="h-40"
                        onClick={finalSubmit}
                        disabled={!allVideosUploaded}
                      >
                        {!allVideosUploaded ? (
                          <div
                            className="spinner-border text-light"
                            style={override}
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </ButtonYellowFill>
                    </div>
                  </BoxYellowOutline>
                  {/* <BoxYellowOutline className="p-3">
                            <h6>Your Assesment Score</h6>
                            <h6>50/60</h6>
                            <div className="d-flex justify-content-between">
                              <ButtonYellowOutline
                                onClick={handleShow}
                                className="h-40"
                              >
                                Record Again
                              </ButtonYellowOutline>
                              <ButtonYellowFill
                                width="120px"
                                className="h-40"
                                onClick={finalSubmit}
                              >
                                Submit
                              </ButtonYellowFill>
                            </div>
                          </BoxYellowOutline> */}
                </div>
              </div>
              <div className="col-0 d-none-768 col-sm-0 justify-content-center">
                <div className="vertical-row-purple mx-auto"></div>
              </div>
              <div className="col-md-6 col-12 col-sm-12">
                <div className="">
                  <div className="d-flex flex-column   w-100 m-auto">
                    <div className=" video-player pb-0">
                      {" "}
                      <JolPlayer
                        className="player m-auto"
                        option={{
                          height: 400,
                          mode: "scaleToFill",
                          width: "auto",
                          language: "en",
                          controls: true,
                          loop: true,
                          setBufferContent: "Loading...",
                          videoType: "video/mp4",
                          nextButton: true,
                          prevButton: true,
                          pausePlacement: "center",
                          isShowWebFullScreen: true,
                          isProgressFloat: true,
                          videoSrc: testvideo[recordedAnsno - 1],
                        }}
                      />
                    </div>
                    <div className="quesetion-ans">
                      <h6 className="question text-center">
                        {questions[recordedAnsno - 1].question}
                      </h6>
                      <div className="d-flex justify-content-between question-no-page">
                        <AiOutlineLeft
                          onClick={() => {
                            if (recordedAnsno === 1) {
                              toast.error(
                                "Already On First video",
                                ToastStyles
                              );
                            } else {
                              setRecordedAnsNo((prev) => prev - 1);
                            }
                          }}
                        />
                        <div className="page">
                          {recordedAnsno}/{testvideo.length}
                        </div>
                        <AiOutlineRight
                          onClick={() => {
                            if (recordedAnsno === testvideo.length) {
                              toast.error("Already On Last video", ToastStyles);
                            } else {
                              setRecordedAnsNo((prev) => prev + 1);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BoxStyle>
          </div>
        </div>
      </InterviewPageBox>
    </>
  );
};

export default FinalReviewAns;
