import React, { useEffect, useState } from "react";
import { Offcanvas, Row } from "react-bootstrap";
import { IoIosMail, IoMdNotifications } from "react-icons/io";
import { NavbarStyled } from "./NavbarStyles";
import user from "assets/image/userDummy.jpeg";
import "./Navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import logo from "assets/image/logo.svg";
import { SidePanelData } from "contants/SidepanelData";
import { ToastStyles } from "components/common/ToastStyle";
import { toast } from "react-hot-toast";
import {
  GetIntervieweeDetails,
  getIntervieweeNotificationReq,
} from "../../axios/apis";
import Cookies from "js-cookie";

export const Navbar = ({ updateprofile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const notification = Cookies.get("Notification");
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      Cookies.remove("isPurchased");
      Cookies.remove("Notification");
      navigate("/");
    }
  };

  const gettime = (oldtime) => {
    const datenow = new Date();
    let newtime = Math.abs(new Date(oldtime) - new Date());
    // console.log(new Date(oldtime), datenow)
    let months = newtime / (30 * 24 * 60 * 60 * 1000);
    let days = newtime / (24 * 60 * 60 * 1000);
    let hours = newtime / (60 * 60 * 1000);
    let minutes = newtime / (60 * 1000);
    let secs = newtime / 1000;
    // console.log(months, days, hours, minutes, secs)
    if (months > 12) {
      return "1yr";
    } else if (months >= 1) {
      return false;
    } else if (days >= 1) {
      return false;
    } else if (hours >= 1) {
      return true;
    } else if (minutes >= 1) {
      return true;
    } else {
      return true;
    }
  };

  const GetUser = async () => {
    try {
      const res = await GetIntervieweeDetails();
      const res2 = await getIntervieweeNotificationReq();
      if (res2?.data?.data?.length > 0) {
        const time = gettime(res2?.data?.data[0]?.createdAt);
        if (time) {
          Cookies.set("Notification", true);
        } else {
          if (time) {
            Cookies.set("Notification", false);
          }
        }
      }
      setUserDetail(res?.data?.message);
      res?.data?.message?.course?.map((course, i) => {
        if (course.courseID == "6343c15fe33839d145810f31") {
          Cookies.set("isPurchased", "true");
          console.log("purchased");
        }
      });
    } catch (error) {
      toast.error("Try Again", ToastStyles);
    }
  };

  useEffect(() => {
    GetUser();
  }, [updateprofile]);
  return (
    <>
      <NavbarStyled>
        <div className="nav-sec-1 d-none-968">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h6 className="nav-head">Hi, {userDetail?.firstname}!</h6>
              {/* <p className="nav-para d-flex align-items-center">
                Trial Account
              </p> */}
            </div>
            {/* <div className="remaining-interview">
              <p className="nav-para ">Interviews Remaining - 1/1</p>
            </div> */}
          </div>
          {/* <Row>
            <div className="d-flex align-items-center">
              <IoIosMail size={24} />
              <p className="nav-para">{userDetail?.email}</p>
            </div>
          </Row> */}
        </div>
        <div className="nav-sec-1 d-flex-968">
          <AiOutlineMenu onClick={handleShow} className="c-pointer" size={30} />
          <Offcanvas className="sidebar-968 " show={show} onHide={handleClose}>
            <Offcanvas.Header className="py-1">
              <div className="m-auto">
                <img style={{ width: "160px" }} src={logo} />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="px-3 py-4">
                <div className="min-height-sidebar">
                  {SidePanelData.slice(0, 5).map((item) => (
                    <NavLink
                      onClick={handleClose}
                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`
                        d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <hr />
                  {SidePanelData.slice(5, 8).map((item) => (
                    <NavLink
                      onClick={handleClose}
                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <a className="sidebar__link hidedash" onClick={Logout}>
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar

                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">LogOut</h6>
                    </div>
                  </a>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="d-flex justify-content-end align-items-center nav-sec-2">
          {/* <div className="nav-level">
            <h6>Level 1</h6>{" "}
          </div> */}
          <NavLink
            to={"/interviewee/notifications"}
            className={`nav-notification ${
              location.pathname === "/notifications" ? "active" : ""
            }`}
          >
            <IoMdNotifications size={26} />
            {notification && <div className="dot-not"></div>}
          </NavLink>
          <div className="d-inline-block profileItem">
            <div className="user-img-cover">
              <img
                className="user-img profile-img-user"
                src={userDetail?.profileImage || user}
                alt="user"
              />
            </div>
            <div className="profileList">
              <ul>
                <li
                  onClick={() => navigate("/interviewee/profile")}
                  className="profile"
                >
                  Profile
                </li>
                <li onClick={Logout} className="logout">
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </NavbarStyled>
    </>
  );
};
