import styled from "styled-components";

export const InterviewBoxStyled = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  &.padding-0 {
    padding: 0;
  }
  &.scroller {
    overflow-y: auto;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    &.h-70 {
      height: calc(100vh - 70px);
    }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: ${({ theme }) => theme.primary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.secondary};
    }
  }
`;
export const PurpleBoxYellowBorder = styled.div`
  background: #f9ecff;
  border: 1px solid #ffa303;
  border-radius: 15px;
  &.padding-0 {
    padding: 0;
  }
  &.bg-white {
    background: #ffffff;
  }
  &.br-50 {
    border-radius: 70px;
  }
`;
export const InterviewPageBox = styled.div`
  min-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;
export const LightPurpleBtn = styled.div`
  height: 40px;
  background: #ae78e5;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
`;
