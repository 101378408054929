import { Table } from "components/Table/Table";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  DashboardBoxStyle,
  Paragraph,
  PinkButton,
  SpanYellow,
  YellowButton,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { PracticeCardData } from "../data";
import { useNavigate } from "react-router";
import PracticeInterviewCard from "../../../components/Card/PracticeInterviewCard";
import { Spinner } from "react-bootstrap";
import { GetIntervieweeDetails } from "../../../axios/apis";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Moment from "react-moment";
import Loading from "components/common/Loading";

export const MyInterview = () => {
  const navigate = useNavigate();
  const [favinterview, setfavInverView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [interviewtype, setInverViewtype] = useState({
    practiceinterview: true,
    jobinterview: false,
  });
  const [interview, setInterView] = useState({
    id: "",
    data: [],
  });
  const [allInterview, setAllInterview] = useState({
    id: "",
    data: [],
  });
  const [uniqueInterView, setUniqueInterView] = useState({
    id: "",
    data: [],
  });

  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      let interviewList = [];
      const response = await GetIntervieweeDetails();
      const unique = [
        ...new Map(
          response?.data?.message?.interviewResults?.map((item) => [
            item?.interview_id?._id,
            item,
          ])
        ).values(),
      ];
      unique?.forEach((interview) => {
        interviewList.push({
          ...interview,
          isUnique: true,
        });
      });
      setUniqueInterView({
        id: "table1",
        data: interviewList?.slice()?.reverse(),
      });
      setInterView({
        id: "allTable",
        data: response?.data?.message?.interviewResults?.slice()?.reverse(),
      });

      let interviewspractice = interviewList?.filter(
        (data) => data?.interview_id?.creator_type !== "corporate"
      );
      setAllInterview({
        id: "table1",
        data: interviewspractice?.slice()?.reverse(),
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllInterviews();
  }, []);
  useEffect(() => {
    if (interviewtype.practiceinterview) {
      let interviewspractice = uniqueInterView.data.filter(
        (data) => data?.interview_id?.creator_type !== "corporate"
      );
      setAllInterview({
        id: "table1",
        data: interviewspractice,
      });
    } else {
      let interviewspractice = uniqueInterView.data.filter(
        (data) => data?.interview_id?.creator_type === "corporate"
      );
      setAllInterview({
        id: "table1",
        data: interviewspractice,
      });
    }
  }, [interviewtype.practiceinterview]);

  // console.log(allInterview);

  const totalscore = (data) => {
    let newdata = data?.filter((data2) => data2?.body !== null);
    let score = newdata?.map((data2) => data2?.body["Total Score"]);
    const res = score?.reduce((a, b) => a + b, 0);
    // debugger
    // console.log((res/newdata.length).toFixed(2));
    return (res / newdata?.length)?.toFixed(2) === "NaN"
      ? 0
      : (res / newdata.length)?.toFixed(2);
  };
  const columns = [
    // {
    //   field: "description", headerName: "JD", minWidth: "150", renderCell: (params) => (
    //     <div className="d-flex align-items-center">hello</div>
    //   ),
    // },
    // {
    //   field: "company",
    //   headerName: "CREATED BY",
    //   minWidth: "150", renderCell: (params) => (
    //     <div className="d-flex align-items-center">{params?.row?.interview_id?.company}</div>
    //   ),
    // },
    // {
    //   field: "icon",
    //   headerName: "",
    //   minWidth: "150",
    //   renderCell: (params) => (
    //     <div className="d-flex align-items-center mx-auto">
    //       <img
    //         className="user-img"
    //         src={params?.row?.interview_id?.icon}
    //         alt="logo"
    //       />
    //     </div>
    //   ),
    // },

    {
      field: "jobcategory",
      headerName: "JOB CATEGORY",
      minWidth: "220",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          {params?.row?.interview_id?.jobcategory}
        </div>
      ),
    },
    {
      field: "jobtitle",
      headerName: "JOB ROLE",
      minWidth: "220",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          {params?.row?.interview_id?.jobtitle}
        </div>
      ),
    },
    // {
    //   field: "experience",
    //   headerName: "EXPERIENCE",
    //   minWidth: "150",
    //   renderCell: (params) => (
    //     <div className="d-flex align-items-center">
    //       {params?.row?.interview_id?.experience}
    //     </div>
    //   ),
    // },
    {
      field: "domain",
      headerName: "INDUSTRY",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          {params?.row?.interview_id?.jobcategory}
        </div>
      ),
    },
    // {
    //   field: "createdAt",
    //   headerName: "Submitted",
    //   minWidth: "150", renderCell: (params) => (
    //     <div className="d-flex align-items-center">{params?.value.slice(0,10)}</div>
    //   ),
    // },
    {
      field: "reports",
      headerName: "ATTEMPTS",
      minWidth: "150",
      renderCell: (data) => (
        <div
          onClick={() => {
            if (data?.row?.isUnique) {
              setAllInterview({
                id: "table2",
                data: interview?.data?.filter(
                  (item) =>
                    item?.interview_id?._id === data?.row?.interview_id?._id
                ),
              });
            } else {
              navigate(`/interviewee/reports/${data.row._id}`);
            }
          }}
          className="d-flex align-items-center text-hover"
        >
          {data?.row?.isUnique ? "View More" : "View Report"}
        </div>
      ),
    },
  ];
  const columns2 = [
    // {
    //   field: "icon",
    //   headerName: "",
    //   minWidth: "50",
    //   renderCell: (params) => (
    //     <div className="d-flex align-items-center">
    //       <img
    //         className="user-img"
    //         src={params?.row?.interview_id?.icon}
    //         alt="logo"
    //       />
    //     </div>
    //   ),
    // },

    {
      field: "updatedAt",
      headerName: "Date of Attempt",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <Moment format="DD/MM/YYYY" date={params?.value} />
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Time of Attempt",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <Moment format="hh:mm A" date={params?.value} />
        </div>
      ),
    },
    {
      field: "results",
      headerName: "Score",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          {params?.value[0].body?.Total_Score || "-"}
        </div>
      ),
    },
    {
      field: "video",
      headerName: "Interview",
      minWidth: "150",
      renderCell: (params) => (
        <>
          {/* interviewtype.jobinterview ? "-" :  */}
          {
            <SpanYellow
              className="c-pointer"
              onClick={() =>
                navigate(`/interviewee/interview-video/${params?.row?._id}`)
              }
            >
              View
            </SpanYellow>
          }
        </>
      ),
    },
    {
      field: "reports",
      headerName: "Reports",
      minWidth: "150",
      renderCell: (data) => {
        // Check if any of the results has a valid Total_Score
        const hasScore = data.row.results.some(
          (result) => result.body && result.body.Total_Score !== undefined
        );

        return (
          <div
            onClick={() => {
              if (hasScore) {
                navigate(`/interviewee/reports/${data.row._id}`);
              }
            }}
            className="d-flex align-items-center text-hover c-pointer"
            style={{
              // cursor: hasScore ? "pointer" : "default",
              color: hasScore ? "#FFA303" : "grey",
            }}
          >
            {hasScore ? "View" : "In Progress"}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container course-btn">
            <div
              className="d-flex align-items-center justify-content-evenly justify-content-sm-start mb-3"
              style={{ marginTop: "10px" }}
            >
              <ButtonBlue
                className={`px-1 px-sm-3 h-40 ${
                  interviewtype?.practiceinterview ? "" : "inactive"
                }`}
                onClick={() => {
                  setInverViewtype({
                    practiceinterview: !interviewtype?.practiceinterview,
                    jobinterview: !interviewtype?.jobinterview,
                  });
                }}
              >
                Practice Interview
              </ButtonBlue>
              <YellowButton
                className={`mx-1 ${
                  interviewtype?.jobinterview ? "px-1 px-sm-3 active" : ""
                }`}
                onClick={() => {
                  setInverViewtype({
                    practiceinterview: !interviewtype?.practiceinterview,
                    jobinterview: !interviewtype?.jobinterview,
                  });
                }}
              >
                Job Interview
              </YellowButton>
            </div>
            <BoxStyle className="my-1 py-3 px-4">
              <div className="mb-0 align-items-center">
                <YellowHeading>
                  {interviewtype?.practiceinterview
                    ? "Practice Interviews"
                    : "Job Interviews"}
                </YellowHeading>
                {/* {allInterview.id !== "table1" && (
                  <ButtonBlue
                    onClick={() => setAllInterview(uniqueInterView)}
                    className="px-2 px-sm-4"
                  >
                    All Interview
                  </ButtonBlue>
                )} */}
              </div>
              {/* <div
            className="d-flex align-items-center mb-3"
            style={{ marginTop: "10px" }}
          >
            <ButtonBlue
              className={`px-2 h-40 ${
                favinterview ? "" : "inactive"
              }`}
              onClick={() => {
                setfavInverView(false)
              }}
            >
              Interview Courses
            </ButtonBlue>
            <YellowButton
              className={`mx-2 ${interview.jobInterview ? "px-2 active" : ""}`}
              onClick={() => {
                setInverView({
                  practiceinterview: false,
                  jobInterview: !interview.jobInterview,
                  jobinterview: false,
                });
              }}
            >
              Job Interviews
            </YellowButton>
            <YellowButton
              className={`mx-2 ${
                favinterview ? "px-2 active" : ""
              }`}
              onClick={() => {
                setfavInverView(true)
              }}
            >
              Favourite
            </YellowButton>
          </div> */}
              {!favinterview && (
                <Table
                  columns={allInterview?.id === "table1" ? columns : columns2}
                  checkbox={false}
                  data={allInterview?.data?.map((item) => {
                    return {
                      ...item,
                      id: item?._id,
                      key: item?._id,
                    };
                  })}
                  limit={10}
                />
              )}
              {/* {favinterview &&
            PracticeCardData.map((item, index) => (
              <PracticeInterviewCard item={item} />
            ))} */}
            </BoxStyle>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
