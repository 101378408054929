import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState } from "react";
import "./sidebar.css";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { Interviewee } from "container/Interviewee/InterviewCourses/Interviewee";
import { SingleCourse } from "container/Interviewee/InterviewCourses/SingleCourse";
import { PracticeInterview } from "container/Interviewee/PracticeInterview/PracticeInterview";
import { PracticeInterview2 } from "container/Interviewee/PracticeInterview/PracticeInterview2";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { MyInterview } from "container/Interviewee/JobInterview/MyInterview";
import { Profile } from "container/Interviewee/Profile/Profile";
import { PracticeJobInterview } from "container/Interviewee/JobInterview/PracticeJobInterview";
import { MyGoal } from "container/Interviewee/MyGoals/MyGoal";
import { HelpDocumentation } from "components/common/Support/HelpDocumentation";
import { SupportFeedback } from "components/common/Support/SupportFeedback";
import { Dashboard } from "container/Interviewee/Dashboard/Dashboard";
import { AiFillHome } from "react-icons/ai";
import { SingleMyInterview } from "container/Interviewer/MyInterview/SingleMyInterview";
import Reports from "container/Interviewee/Reports/Reports";
import Notification from "container/Interviewee/Notifications/Notification";
import { JobInterview } from "container/Interviewee/PracticeInterview/JobInterview";
import ApplyForInterview from "container/Interviewee/JobInterview/ApplyForInterview";
import Cookies from "js-cookie";

export const SideBarAndHeader = ({ data }) => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const [updateprofile, setupdateprofile] = useState(true);
  const navigate = useNavigate();
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      Cookies.remove("Notification");
      navigate("/");
    }
  };
  return (
    <>
      <div className="d-flex">
        <div className={`${hideSidebar ? "col-w-6" : "col-w-20"} d-none-968`}>
          <SidePanel hideSidebar={hideSidebar}>
            <div className="px-3 py-4">
              <div className="min-height-sidebar">
                {data.slice(0, 5).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <hr />
                {data.slice(5, 8).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <a className="sidebar__link" onClick={Logout}>
                  {!hideSidebar ? (
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar

                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">Logout</h6>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center py-2 wrapper__sidebar wrapper__sidebar_hide cursor-pointer">
                      <AiFillHome />
                    </div>
                  )}
                </a>
              </div>
              <div
                className="sidebar__link position-end"
                onClick={() => {
                  setHideSidebar(!hideSidebar);
                }}
              >
                {!hideSidebar ? (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar cursor-pointer">
                    <HiOutlineArrowNarrowLeft />
                    <h6 className="sidebar__heading px-2 mt-2">Hide Sidebar</h6>
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center py-2 wrapper__sidebar 
                  wrapper__sidebar_hide
                  cursor-pointer"
                  >
                    <HiOutlineArrowNarrowRight />
                  </div>
                )}
              </div>
            </div>
          </SidePanel>
        </div>
        <div className={`${hideSidebar ? "col-w-94" : "col-w-80"} w-100-968`}>
          <div className="w-100">
            <Navbar
              updateprofile={updateprofile}
              setupdateprofile={setupdateprofile}
            />
            <div className="main-content ">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/:id" element={<Dashboard />} />
                <Route path="/interview-courses" element={<Interviewee />} />
                <Route
                  path="/interview-courses/:id"
                  element={<SingleCourse />}
                />
                <Route
                  path="/practice-interview"
                  element={<PracticeInterview2 />}
                />
                <Route
                  path="/practice-interview2"
                  element={<PracticeInterview />}
                />
                <Route path="/job-interview" element={<JobInterview />} />
                <Route
                  path="/job-interview/apply/:id"
                  element={<ApplyForInterview />}
                />
                <Route path="/my-interview" element={<MyInterview />} />
                <Route path="/reports/:id" element={<Reports />} />
                <Route
                  path="/my-interview/:id"
                  element={<PracticeJobInterview />}
                />
                <Route
                  path="/my-interviews/:id"
                  element={<SingleMyInterview />}
                />
                <Route
                  path="/interview-video/:id"
                  element={<PracticeJobInterview />}
                />
                <Route path="/my-goals" element={<MyGoal />} />
                <Route
                  path="/profile"
                  element={
                    <Profile
                      updateprofile={updateprofile}
                      setupdateprofile={setupdateprofile}
                    />
                  }
                />
                <Route path="/support-feedback" element={<SupportFeedback />} />
                <Route path="/help" element={<HelpDocumentation />} />
                <Route path="/notifications" element={<Notification />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
