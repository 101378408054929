import React, { useEffect, useState } from "react";
import {
  BoxStyle,
  DashboardBoxStyle,
  Input,
  ButtonBlue,
  YellowHeading,
} from "components/common/CommonStyles";
import { PracticeCardData } from "../data";
import "./interviewee.css";
import { Form, Spinner } from "react-bootstrap";

import PracticeInterviewCard2 from "../PracticeInterview/PracticeInterviewCard2";
import { GenerateInterview, GetInterviewsByUser } from "../../../axios/apis";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
export const PracticeInterview2 = () => {
  const [loading, setLoading] = useState(false);
  const [jobRole, setJobRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [numQuestions, setNumQuestions] = useState(5); // Default to 5 questions
  const [interviews, setInterviews] = useState([]); // Store fetched interviews
  const [interviewLevel, setInterviewLevel] = useState("Fresher"); // Default to Fresher
  const [companySector, setCompanySector] = useState(""); // Input for company sector/domain
  const navigate = useNavigate(); // Initialize useNavigate hook
  console.log("LocalStorage", localStorage);

  const userID = localStorage.getItem("userId");
  console.log("user ID:", userID);

  const token = Cookies.get("token");
  console.log("token:", token);
  useEffect(() => {
    if (!userID) {
      alert("User not logged in. Redirecting to login page.");
      navigate("/");
      return;
    }

    // Fetch interviews on component load
    const fetchInterviews = async () => {
      try {
        setLoading(true);
        const response = await GetInterviewsByUser(userID);
        console.log("Fetched Interviews:", response.data);
        // Sort interviews by `createdAt` in descending order
        const sortedInterviews = (response.data.interviews || []).sort(
          (a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA; // Descending order
          }
        );

        setInterviews(sortedInterviews); // Set sorted interviews
      } catch (error) {
        console.error("Error fetching interviews:", error);
        setInterviews([]);
        // alert("Failed to fetch interviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchInterviews();
  }, [userID, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jobRole || !companyName || !companySector || !numQuestions) {
      alert("Please fill in all fields.");
      return;
    }

    // const prompt = `Generate 5 interview questions for the role of ${jobRole} at ${companyName}.`;

    setLoading(true);
    // Get user details from token or local storage
    const creatorID = localStorage.getItem("userId");
    const creator_type = Cookies.get("role"); // Example for user type storage
    if (!creatorID || !creator_type) {
      alert("User details are missing. Please log in again.");
      setLoading(false);
      return;
    }
    const formData = {
      jobRole,
      companyName,
      numQuestions,
      interviewLevel,
      companySector,
      creatorID,
      creator_type,
    };
    try {
      const response = await GenerateInterview(formData);
      console.log("Response:", response);
      const interviewId = response.data.interview._id;
      console.log("Redirecting to /interview/", interviewId);

      // Redirect to the interview page
      navigate(`/interview/${interviewId}`);
    } catch (error) {
      console.error("Error generating questions:", error);
      alert("Failed to generate questions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container my-0">
            <div className="row">
              {/* <YellowHeading className="my-2">
                Practice Interviews
              </YellowHeading> */}
              <div
                style={{
                  padding: "20px",
                  margin: "20px",
                  backgroundColor: "#F0DBFF",
                  borderRadius: "20px",
                  width: "-webkit-fill-available",
                }}
              >
                <h3 className="my-2">Practice Interviews</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group style={{ marginBottom: "15px" }}>
                    <Form.Label>Job Role:</Form.Label>
                    <Form.Control
                      type="text"
                      value={jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      placeholder="Enter the job role"
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "5px",
                      }}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "15px" }}>
                    <Form.Label>Your Dream Company Name:</Form.Label>
                    <Form.Control
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      placeholder="Enter your dream company name"
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "5px",
                      }}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "15px" }}>
                    <Form.Label>Interview Level:</Form.Label>
                    <Form.Select
                      value={interviewLevel}
                      onChange={(e) => setInterviewLevel(e.target.value)}
                    >
                      <option value="Fresher">Fresher</option>
                      <option value="Experienced">Experienced</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "15px" }}>
                    <Form.Label>Company Sector/Domain/Industry:</Form.Label>
                    <Form.Control
                      type="text"
                      value={companySector}
                      onChange={(e) => setCompanySector(e.target.value)}
                      placeholder="Enter the company sector/domain"
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "5px",
                      }}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "15px" }}>
                    <Form.Label>Number of Questions:</Form.Label>
                    <Form.Control
                      type="number"
                      value={numQuestions}
                      onChange={(e) => setNumQuestions(e.target.value)}
                      min="1"
                      max="10"
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "5px",
                      }}
                    />
                  </Form.Group>
                  <ButtonBlue
                    type="submit"
                    style={{
                      padding: "10px 20px",
                    }}
                  >
                    Generate Interview
                  </ButtonBlue>
                </Form>

                {loading && <p>Loading...</p>}
              </div>
              <div className="col-12 mt-5">
                <h3 className="my-2">Your Interviews</h3>
                {interviews.length > 0 ? (
                  interviews
                    .filter((item) => {
                      return item.draft === false; // Filter interviews where draft is "true"
                    })
                    .map((item, index) => (
                      <PracticeInterviewCard2 key={index} item={item} />
                    ))
                ) : (
                  <>
                    <h6>Currently No Practice Interview.</h6>
                  </>
                )}
              </div>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
