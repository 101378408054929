import { AiFillBook, AiFillHome } from "react-icons/ai";
import {
  MdGroup,
  MdContacts,
  MdSchool,
  MdFeedback,
  MdOutlineHelp,
} from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

import { create_UUID } from "helper/uuid";

export const SidePanelData = [
  {
    id: create_UUID(),
    name: "Dashboard",
    path: "/interviewee/dashboard",
    icon: <AiFillHome />,
  },
  {
    id: create_UUID(),
    path: "/interviewee/practice-interview",
    name: "Practice Interview",
    icon: <MdGroup />,
  },
  {
    id: create_UUID(),
    path: "/interviewee/job-interview",
    name: "Job Posting",
    icon: <MdContacts />,
  },
  {
    id: create_UUID(),
    path: "/interviewee/my-interview",
    name: "Interview Reports",
    icon: <MdSchool />,
  },
  {
    id: create_UUID(),
    name: "Interview Tips",
    path: "/interviewee/interview-courses",
    icon: <AiFillBook />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/interviewee/my-goals",
  //   name: "My Goals",
  //   icon: <MdSchool />,
  // },
  // {
  //   id: create_UUID(),
  //   path: "/interviewee/profile",
  //   name: "My Profile",
  //   icon: <BsFillPersonFill />,
  // },
  // {
  //   id: create_UUID(),
  //   path: "/interviewee/support-feedback",
  //   name: "Support & Feedback",
  //   icon: <MdFeedback />,
  // },
  // {
  //   id: create_UUID(),
  //   path: "/interviewee/help",
  //   name: "Help & Documentation",
  //   icon: <MdOutlineHelp />,
  // },
];
