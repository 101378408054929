import React from "react";
import loadingVideo from "./Comp_InterviewPro.mp4";
const Loading = () => {
  return (
    <video
      src={loadingVideo}
      style={{ width: "300px" }}
      autoPlay={true}
      loop
      muted
    ></video>
  );
};

export default Loading;
