import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  Paragraph,
  PinkButton,
  SpanYellow,
} from "components/common/CommonStyles";
import job from "assets/image/job.png";
import expiry from "assets/image/expiry.png";
import time from "assets/image/time.png";
import stock from "assets/image/stock.png";
import heart from "assets/image/heart.png";
import tick from "assets/image/tick.png";
import NotPurchasedModal from "components/common/NotPurchasedModal";
import Cookies from "js-cookie";
import Logo from "../../../assets/image/logosmall.png";
import {
  ApplyInterview,
  GetIntervieweeDetails,
  UpdateIntervieweeProfile,
} from "../../../axios/apis";
import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";

const fetchSkillDetails = async (skillId) => {
  try {
    const response = await fetch(
      // `https://interview-api.viosa.in/interview/skills/${skillId}`
      `https://api.viosa.in/interview-pro/interview/skills/${skillId}`
      // `http://localhost:3200/interview-pro/interview/skills/${skillId}`
    );
    const data = await response.json();
    return data.skills; // Assuming the API returns the skill object with a 'skills' field containing the skill name.
  } catch (error) {
    // console.error(`Error fetching skill details for ${skillId}:`, error);
    return null;
  }
};
const PracticeInterviewCard2 = ({ item, isAttempted }) => {
  const [readmore, setreadmore] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const isPurchased = Cookies.get("isPurchased");
  const [userDetail, setUserDetail] = useState({});
  const [skillNames, setSkillNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateloading, setupdateLoading] = useState(false);

  // console.log("Component Rendered with item:", item);
  useEffect(() => {
    if (item && item.skills) {
      const skills = item.skills.map((skill) => skill.skills);
      setSkillNames(skills);
    }
    GetUser();
  }, [item.skills]);

  if (!item) {
    // console.log("Item is null or undefined");
    return null;
  }

  const GetUser = async () => {
    setLoading(true);
    try {
      const res = await GetIntervieweeDetails();
      setUserDetail(res?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  const renderJobDetails = () => (
    <>
      {item?.interviewLevel && (
        <div className="px-1 d-flex align-items-center">
          <img
            className="rounded-circle mx-1"
            height={17}
            width={17}
            src={tick}
            alt="tick"
          />
          <span className="fs-14" style={{ fontSize: "14px" }}>
            {item.interviewLevel}
          </span>
        </div>
      )}
      {item?.jobcategory && (
        <div className="px-1 d-flex align-items-center">
          <img
            className="rounded-circle mx-1"
            height={17}
            width={17}
            src={tick}
            alt="tick"
          />
          <span className="fs-14" style={{ fontSize: "14px" }}>
            {item.jobcategory}
          </span>
        </div>
      )}
      {/* <div className="px-1 d-flex align-items-center">
        <img
          className="rounded-circle mx-1"
          height={17}
          width={17}
          src={job}
          alt="job"
        />
        <span className="fs-14" style={{ fontSize: "14px" }}>
          {item.experience}&nbsp;Years
        </span>
      </div> */}
      {/* <div className="px-1 d-flex align-items-center">
        <img
          className="rounded-circle mx-1"
          height={17}
          width={15}
          src={stock}
          alt="stock"
          style={{ objectFit: "contain" }}
        />
        <span className="fs-14" style={{ fontSize: "14px" }}>
          {item.jobcity}
        </span>
      </div> */}
    </>
  );

  return (
    <>
      <NotPurchasedModal show={show} setShow={setShow} />
      <BoxStyle className="pt-3 mb-3 padding-5 linear" key={item._id}>
        <div className="container interview-card">
          <div className="row">
            <div className="mb-1">
              <div className="d-flex justify-content-between align-items-center mb-2 skills-container">
                <div className="mx-2">
                  <DarkHeading className="px-2">{item.jobtitle}</DarkHeading>
                  <div className="mb-3 d-none-525">
                    <h6 className="px-2">{item.company}</h6>
                  </div>
                  <div className="mb-3 d-none-525">
                    <div className="d-flex">{renderJobDetails()}</div>
                  </div>
                </div>
                <div className="w-100-525">
                  <ButtonBlue
                    className="px-3 practice-btn-interview w-160"
                    onClick={() => {
                      if (isPurchased === "false") {
                        setShow(true);
                        // console.log("modal open")
                      } else {
                        navigate(`/interview/${item?._id}`);
                      }
                    }}
                  >
                    Practice Interview
                  </ButtonBlue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BoxStyle>
    </>
  );
};

export default PracticeInterviewCard2;
