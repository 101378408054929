import {
  ButtonBlue,
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Footer from "./Footer";
import Header from "./Header";
import "./interview.css";
import { MultiStepProgress } from "components/ProgressBar/MultiStepProgress";
import {
  InterviewBoxStyled,
  InterviewPageBox,
  PurpleBoxYellowBorder,
} from "./InterviewStyled";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Cookies from "js-cookie";
import {
  AppliedStudents,
  GetSingleInterview,
  ShortListForInterview,
} from "../../axios/apis";

import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../redux/slices/languageSlice";
const InstructionPage1 = () => {
  const name = Cookies.get("name");
  const [show, setShow] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const navigate = useNavigate();
  const [check, setcheck] = useState(false);
  const params = useParams();
  const logintype = Cookies.get("logintype");
  const [interviewdata, setInterviewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [appliedstudents, setAppliedstudents] = useState([]);
  const [candidates, setcandidates] = useState(0);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );

  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    dispatch(setLanguage(selectedLang)); // Update language in Redux
    localStorage.setItem("preferredLanguage", selectedLang); // Persist the preference
  };

  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      const res = await AppliedStudents({ interviewId: params.id });
      setAppliedstudents(res.data.candidates);
      // console.log(res)
      console.log(response);
      setInterviewData(response.data.interview);
      let unique = [
        ...new Set(
          response.data.interview?.interviewResult.map(
            (data) => data.attemptedby_id?._id
          )
        ),
      ];
      // console.log(unique);
      setcandidates(unique.length);
      // debugger;
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  console.log("interviewdata", interviewdata);
  useEffect(() => {
    GetInterviewDetails();
  }, [params.id]);
  const { jobtitle, interviewquestions, company } = interviewdata;
  const totalQuestions = interviewquestions ? interviewquestions.length : 0;
  // Calculate total time required based on question durations
  let totalTime = 0;
  interviewquestions?.forEach((question) => {
    totalTime += question.duration || 0;
  });

  // Convert totalTime to minutes and seconds
  const minutes = Math.floor(totalTime / 60);
  const seconds = totalTime % 60;

  return (
    <>
      <Modal
        show={termsModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <p className="text-center mb-0">Candidate Terms Of Use</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <div style={{ height: "45vh", overflowY: "auto" }}>
            <p>
              <b>1. Acceptance of Terms:</b>
              <br />
              By accessing and using the VIOSA AI Interview pro Tool, you agree
              to abide by these terms and conditions. If you do not agree,
              please refrain from using the tool.
            </p>{" "}
            <p>
              <b>2. Purpose of the Tool:</b>
              <br />
              VIOSA AI Interview Mock Tool is designed for educational purposes,
              allowing students to practice mock interviews. It analyzes video
              responses on parameters such as confidence, professionalism,
              positivity, and industry standards.
            </p>{" "}
            <p>
              <b>3. User Eligibility:</b>
              <br />
              This tool is intended for students and individuals seeking to
              enhance their interview skills. Users must be at least 18 years
              old or have parental/guardian consent.
            </p>{" "}
            <p>
              <b>4. Privacy and Data Usage:</b>
              <br />
              VIOSA respects user privacy. Video responses are processed to
              provide feedback on interview performance. User data is treated
              confidentially and is not shared with third parties without
              explicit consent.
            </p>{" "}
            <p>
              <b>5. Responsible Use:</b>
              <br />
              Users are responsible for the content of their video responses.
              VIOSA reserves the right to disable accounts violating ethical
              guidelines or engaging in inappropriate behavior.
            </p>{" "}
            <p>
              <b>6. Feedback Accuracy:</b>
              <br />
              VIOSA AI provides feedback based on algorithms analyzing various
              parameters. While efforts are made to ensure accuracy, users
              should interpret feedback as suggestions and not absolute
              interviews.
            </p>{" "}
            <p>
              <b>7. Intellectual Property:</b>
              <br />
              Users retain ownership of their video responses. VIOSA retains the
              right to use anonymized, aggregated data for research and
              improvement purposes.
            </p>{" "}
            <p>
              <b>8. Availability and Technical Issues:</b>
              <br />
              VIOSA strives to maintain tool availability but is not liable for
              disruptions due to technical issues. Users are encouraged to
              report any problems for prompt resolution.
            </p>{" "}
            <p>
              <b>9. Disclaimer of Warranties:</b>
              <br />
              VIOSA provides the tool "as is" without warranties of any kind,
              expressed or implied. Use the tool at your own risk.
            </p>{" "}
            <p>
              <b>10. Modification of Terms:</b>
              <br />
              VIOSA reserves the right to modify these terms at any time. Users
              will be notified of significant changes, and continued use
              constitutes acceptance of the modified terms.
            </p>{" "}
            <p>
              <b>11. Termination:</b>
              <br />
              VIOSA may terminate or suspend access to the tool without notice
              for violations of these terms or for any other reason deemed
              necessary.
            </p>{" "}
            <p>
              <b>12. Contact Information:</b>
              <br />
              For inquiries or concerns regarding these terms or the VIOSA AI
              Interview Mock Tool, please contact{" "}
              <a href="mailto:mail@viosa.com">mail@viosa.com</a>
            </p>{" "}
            <p>
              By using VIOSA AI Interview Mock Tool, you acknowledge that you
              have read, understood, and agreed to these terms and conditions.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue onClick={() => setTermsModal(false)} className="w-100">
            Continue
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      <Header />
      <InterviewBoxStyled className="scroller interview-form h-70 padding-0">
        <InterviewPageBox className="px-5 instruction-box">
          <div className="col-12">
            {show ? (
              <>
                <div className=" py-3 section-2 justify-content-center d-flex col-12">
                  <div className="col-md-4 col-sm-8">
                    <PurpleBoxYellowBorder className=" p-3 ">
                      <div className="d-flex flex-column align-items-center mt-3">
                        <h5>Start Practice</h5>
                        <h6>Important Notice</h6>
                      </div>

                      <hr />
                      <ol className="d-flex flex-column align-items-center">
                        <li>First, test with a practice question</li>
                        <li>It won't count as a submission</li>
                        <li>Click Start Test to begin</li>
                      </ol>
                      <div className="px-5 py-3">
                        <ButtonYellowFill
                          onClick={() =>
                            navigate(`/interview/video-interview/${params.id}`)
                          }
                        >
                          Start
                        </ButtonYellowFill>
                      </div>
                    </PurpleBoxYellowBorder>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="section-1 p-3">
                  <div className="mt-4 mb-2 step-counter">
                    <MultiStepProgress
                      progress={logintype === "interviewLink" ? 1 : 2}
                    />
                  </div>
                </div>
                <div className="section-2 my-3 w-100 d-flex flex-column px-3">
                  <PurpleBoxYellowBorder className=" w-100">
                    <div className="p-5 interview-box">
                      <h5>Dear {name}</h5>
                      <h6 className="my-3">
                        As part of the online interview for {jobtitle} with{" "}
                        {company || "VIOSA"}, you are required to complete a
                        video interview.
                      </h6>
                      <h5>Instruction:</h5>
                      <ul>
                        <li>
                          {" "}
                          This video interview consists of {totalQuestions}{" "}
                          {totalQuestions === 1 ? "question" : "questions"}.
                        </li>
                        <li>
                          {" "}
                          The estimated time to complete the interview is
                          approximately {minutes} minutes {seconds} seconds.
                        </li>
                        <li>
                          You are required to complete your interview within a
                          single sitting.
                        </li>
                        <li>
                          Once you are presented with your first question, click
                          on the "Play" button for the bot to read the question,
                          once the bot completes the question "Start Recording"
                          button will appear. Once you are prepared to answer
                          click on the "Start Recording" button.
                        </li>
                        <li>
                          Remember to keep track of the timer while preparing or
                          recording your response.
                        </li>
                        <li>
                          You will be able to review your video responses at the
                          end of the interview. If you feel that your responses
                          are unsatisfactory, you have the choice to retake the
                          entire interview
                        </li>
                      </ul>
                      <h6 className="my-3">
                        However, the order and nature of questions are subject
                        to change to ensure you come across as natural and
                        spontaneous during the video interview.
                      </h6>
                      <h5>Equipment/Environment Check:</h5>
                      <ul>
                        <li>
                          {" "}
                          Find a quiet spot with ample lighting and strong WiFi
                          to take your interview.
                        </li>
                        <li>
                          InterviewPro recording only works in Chrome and
                          Firefox at the moment. Ensure that you are using
                          either of these browsers to take your interview.
                        </li>
                        <li>
                          To proceed with the video interview, we will require
                          access to your video camera and your microphone.
                        </li>
                        <li>
                          You will be shown a practice question where you can
                          test your audio and video quality before starting the
                          actual interview.
                        </li>
                        <li>
                          Be sure to position yourself well within the frame of
                          your screen, sit upright and look straight into your
                          camera when you present your responses.
                        </li>
                      </ul>
                      <h6 className="my-3">
                        Click on the "Proceed" button whenever you're ready. All
                        the best!
                      </h6>
                      <hr />
                      <label htmlFor="language">Select Language:</label>
                      <select
                        id="language"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                      >
                        <option value="en">English</option>
                        {/* <option value="hi">Hindi</option> */}
                      </select>
                      <p>Selected Language: {selectedLanguage}</p>
                      <Form.Group
                        className="mb-3 align-items-baseline d-flex"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          name="check"
                          checked={check}
                          onChange={(e) => setcheck(!check)}
                          className="checkbox"
                        />
                        <h6 className="px-3">
                          {" "}
                          By applying, you agree with{" "}
                          <span
                            onClick={() => setTermsModal(true)}
                            className="yellow cursor-pointer"
                          >
                            VIOSA.IN Candidate Terms of Use
                          </span>{" "}
                        </h6>
                      </Form.Group>
                      <div className="d-flex justify-content-start my-4">
                        <ButtonYellowOutline
                          onClick={() => {
                            if (!check) {
                              toast.error(
                                "Please accept terms and conditions to start the interview",
                                ToastStyles
                              );
                            } else {
                              setShow(true);
                            }
                          }}
                          className="px-5"
                          style={{ background: "transparent" }}
                        >
                          {" "}
                          <span className="yellow">Start</span>
                        </ButtonYellowOutline>
                      </div>
                    </div>
                  </PurpleBoxYellowBorder>
                </div>
              </>
            )}
          </div>
        </InterviewPageBox>
        <Footer />
      </InterviewBoxStyled>
    </>
  );
};

export default InstructionPage1;
