import { InterviewReport } from "../../../axios/apis";
import {
  BoxStyle2,
  DashboardBoxStyle,
  PurpuleHeading,
  ReportFrontPage,
  YellowHeading,
  ButtonBlue,
} from "components/common/CommonStyles";
import { ToastStyles } from "components/common/ToastStyle";
import React, { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { useParams } from "react-router";
import ReportCard from "./ReportCard";
import CardTop from "assets/image/Rectangle 3.png";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import logo from "assets/image/logo.svg";
import bg from "assets/image/Marketing.png";
import user from "assets/image/userDummy.jpeg";
import ReportCardNull from "./ReportCardNull";
import Loading from "components/common/Loading";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";

const Reports = () => {
  const [resultdata, setresultdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [interviewdetail, setinterviewdetail] = useState({});
  const [isReadyForPDF, setIsReadyForPDF] = useState(false); // New state to track readiness for PDF
  const [totalscore, settotalscore] = useState({
    confidence: 0,
    energy: 0,
    optimistic: 0,
    passion: 0,
    industryKeyword: 0,
    grooming: 0,
    clarity: 0,
    fluent: 0,
    speed: 0,
    articulation: 0,
    sociability: 0,
    facialExpression: 0,
    energyVideo: 0,
    pace: 0,
    sentiments: 0,
    introextro: 0,
    openness: 0,
    agreeable: 0,
    neuroticism: 0,
    conscientious: 0,
  });
  // console.log(userDetail);
  const getQuestion = (question, selectedLanguage = "en") => {
    if (!question) return "Question not available"; // Fallback if question is null or undefined
    if (typeof question === "string") return question; // Old schema: return the question as is
    return (
      question[selectedLanguage] || question.en || "Question not available"
    ); // New schema: use the selected language or fallback to English
  };

  const params = useParams();
  const GetResult = async () => {
    setLoading(true);
    try {
      let data = {
        id: params.id,
      };
      const res = await InterviewReport(data);
      // console.log(res);
      // console.log("result", res.data.data.results);
      setUserDetail(res.data.data);
      setresultdata(res.data.data.results);
      setinterviewdetail(res.data.data.interview_id);
      let allScore = {
        confidence: 0,
        energy: 0,
        optimistic: 0,
        passion: 0,
        industryKeyword: 0,
        grooming: 0,
        clarity: 0,
        fluent: 0,
        speed: 0,
        articulation: 0,
        sociability: 0,
        facialExpression: 0,
        energyVideo: 0,
        pace: 0,
        sentiments: 0,
        introextro: 0,
        openness: 0,
        agreeable: 0,
        neuroticism: 0,
        conscientious: 0,
      };
      res?.data?.data?.results?.map((data) => {
        if (data.body !== null && data.body !== "Video cannot be parsed!") {
          allScore = {
            confidence:
              allScore.confidence +
              (data.body["Energy_Voice"] +
                data.body["Energy_Level_Video"] +
                data.body["Sentiment_Analysis_Text"] +
                data.body["Clarity_Text"] +
                data.body["Communication_Video"]) /
                5,
            energy:
              allScore.energy +
              (data.body["Energy_Voice"] + data.body["Energy_Level_Video"]) / 2,
            optimistic:
              allScore.optimistic +
              data.body["Positive_Emotion_Facial_Expression"],
            passion:
              allScore.passion +
              (data.body["Energy_Voice"] +
                data.body["Pace_Text"] +
                data.body["Clarity_Text"]) /
                3,
            industryKeyword: allScore.industryKeyword,
            grooming: allScore.grooming,
            clarity: allScore.clarity + data.body["Clarity_Text"],
            fluent: allScore.fluent + data.body["Communication_Video"],
            speed: allScore.speed + data.body["Energy_Voice"],
            articulation:
              allScore.articulation +
              (data.body["Communication_Video"] + data.body["Clarity_Text"]) /
                2,
            sociability: allScore.sociability + data.body["Sociability_Video"],
            facialExpression:
              allScore.facialExpression +
              data.body["Positive_Emotion_Facial_Expression"],
            energyVideo: allScore.energyVideo + data.body["Energy_Level_Video"],
            pace: allScore.pace + data.body["Pace_Text"],
            sentiments:
              allScore.sentiments + data.body["Sentiment_Analysis_Text"],
            introextro:
              allScore.introextro +
              (data.body["Sociability_Video"] + data.body["Energy_Voice"]) / 2,
            openness:
              allScore.openness +
              (data.body["Energy_Level_Video"] +
                data.body["Energy_Voice"] +
                data.body["Positive_Emotion_Facial_Expression"] +
                data.body["Communication_Video"] +
                data.body["Clarity_Text"]) /
                5,
            agreeable:
              allScore.agreeable +
              (data.body["Positive_Emotion_Facial_Expression"] +
                data.body["Clarity_Text"]) /
                2,
            neuroticism:
              allScore.neuroticism +
              (data.body["Sentiment_Analysis_Text"] +
                data.body["Energy_Voice"]) /
                2,
            conscientious:
              allScore.conscientious +
              (data.body["Pace_Text"] +
                data.body["Clarity_Text"] +
                data.body["Sentiment_Analysis_Text"]) /
                3,
            industryKeyword:
              allScore.industryKeyword + data.body["Professionalism_Video"],
          };
        }
      });
      settotalscore(allScore);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  ChartJS.register(ArcElement, Tooltip, Legend);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            style={{
              height: "10px",
              borderRadius: "5px",
              background: "#f4f4f4",
            }}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const data = {
    labels: [
      `Extraversion-Introversion (${(
        totalscore.introextro / resultdata.length
      ).toFixed(0)}%)`,
      `Openness (${(totalscore.openness / resultdata.length).toFixed(
        0
      )}%)                       `,
      `Agreeableness (${(totalscore.agreeable / resultdata.length).toFixed(
        0
      )}%)               `,
      `Neuroticism (${(totalscore.neuroticism / resultdata.length).toFixed(
        0
      )}%)                    `,
      `Conscientiousness (${(
        totalscore.conscientious / resultdata.length
      ).toFixed(0)}%)         `,
    ],
    datasets: [
      {
        data: [
          totalscore.introextro / resultdata.length,
          totalscore.openness / resultdata.length,
          totalscore.agreeable / resultdata.length,
          totalscore.neuroticism / resultdata.length,
          totalscore.conscientious / resultdata.length,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          // 'rgba(255, 159, 64, 0.5)',
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          // 'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
    options: {
      maintainAspectRatio: false, // This will allow the chart to fit into the container's dimensions
      responsive: true,
    },
  };

  useEffect(() => {
    GetResult();
  }, []);
  // console.log(resultdata);
  // const downloadPDF = async () => {
  //   const input = document.getElementById("reportContent");
  //   const canvas = await html2canvas(input, {
  //     scale: 2, // Increase scale for better resolution
  //     useCORS: true, // Helps with loading images from other domains
  //     logging: true, // Useful for debugging
  //     scrollX: 0,
  //     scrollY: 0,
  //     windowWidth: document.documentElement.offsetWidth,
  //     windowHeight: document.documentElement.offsetHeight,
  //   });

  //   const imgData = canvas.toDataURL("image/png");
  //   const pdf = new jsPDF({
  //     orientation: "portrait",
  //     unit: "mm",
  //     format: [canvas.width, canvas.height],
  //   });

  //   // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
  //   // Adjust dimensions to fit the A4 format
  //   const imgProps = pdf.getImageProperties(imgData);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();
  //   const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);
  //   heightLeft -= pdfHeight;

  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
  //     heightLeft -= pdfHeight;
  //   }
  //   pdf.save("interview-report.pdf");
  // };

  const printReport = () => {
    window.print();
  };

  const downloadhtml2PDF = () => {
    const element = document.getElementById("reportContent");

    const options = {
      margin: 0.1,
      filename: "interview-report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate PDF
    html2pdf().set(options).from(element).save();
  };

  return (
    <>
      <Toaster />
      {/* <button onClick={downloadPDF} style={{ marginTop: "20px" }}>
        Download PDF
      </button> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "20px",
        }}
      >
        <ButtonBlue
          onClick={downloadhtml2PDF}
          style={{ marginLeft: "20px", padding: "10px" }}
        >
          Download Report
        </ButtonBlue>
        <ButtonBlue
          onClick={printReport}
          style={{ marginLeft: "10px", padding: "10px" }}
        >
          Print Report
        </ButtonBlue>
      </div>

      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <div id="reportContent">
          <DashboardBoxStyle className="d-flex align-items-center justify-content-center">
            <BoxStyle2 className="border-0  mt-0 mx-0 mx-md-5 p-0">
              {resultdata[0]?.body === null ? (
                <>
                  <ReportFrontPage className="report-front-page flex-wrap">
                    <div className=" col-12 d-flex justify-content-center align-items-center flex-column">
                      <h4>Report is in Progress</h4>
                    </div>
                  </ReportFrontPage>
                </>
              ) : (
                <>
                  <ReportFrontPage
                    className="report-front-page flex-wrap"
                    style={{ height: "500px" }}
                  >
                    {/* Logo moved to the top left corner */}
                    <div className="col-12 d-flex align-items-start">
                      <div className="" style={{ padding: "0px 10px" }}>
                        <div className="">
                          <img
                            src={logo}
                            alt="company logo"
                            style={{ width: "60%" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 d-flex justify-content-center align-items-center flex-column">
                      {/* <div className="box-border-report mb-4">
                        <div className="box-report">
                          <img src={logo} alt="" />
                        </div>
                      </div> */}
                      <div className=" box-border-report mb-4">
                        <div className="box-report px-2 px-sm-3 ">
                          <YellowHeading className="centerText">
                            Interview
                          </YellowHeading>
                          <PurpuleHeading>Assessment Report</PurpuleHeading>
                        </div>
                      </div>
                      <div className="box-border-report">
                        <div className="box-report px-2 py-2 px-sm-3 py-sm-4">
                          <PurpuleHeading className="text-center">
                            {userDetail?.attemptedby_id?.firstname +
                              " " +
                              userDetail?.attemptedby_id?.lastname || "name"}
                          </PurpuleHeading>
                          <YellowHeading className="text-center text-wrap">
                            {interviewdetail?.jobtitle}
                          </YellowHeading>
                          <PurpuleHeading className="text-center">
                            Assessment Date
                          </PurpuleHeading>
                          <YellowHeading className="text-center">
                            {userDetail?.createdAt?.slice(0, 10) || "Date"}
                          </YellowHeading>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 d-flex justify-content-center align-items-center flex-column">
                      {/* <div className="box-border-report">
                        <div className="box-report px-2 py-2 px-sm-3 py-sm-4">
                          <div className="d-flex justify-content-center">
                          </div>
                          <PurpuleHeading className="text-center">
                            {userDetail?.attemptedby_id?.firstname +
                              " " +
                              userDetail?.attemptedby_id?.lastname || "name"}
                          </PurpuleHeading>
                          <YellowHeading className="text-center text-wrap">
                            {interviewdetail?.jobcategory}
                          </YellowHeading>
                          <PurpuleHeading className="text-center">
                            Assessment Date
                          </PurpuleHeading>
                          <YellowHeading className="text-center">
                            {userDetail?.createdAt?.slice(0, 10) || "Date"}
                          </YellowHeading>
                        </div>
                      </div> */}
                      <div className="">
                        <img
                          src={bg}
                          alt="company logo"
                          style={{ width: "85%" }}
                        />
                      </div>
                    </div>
                  </ReportFrontPage>
                  <div className="container p-0 my-0">
                    <div className="res-box col-12  mb-0">
                      {/* <img src={CardTop} style={{
                  width: "100%"
                }} className="res-top-img" alt="topborder" /> */}
                      <div className="res-bottom"></div>

                      <div className=" px-1 px-sm-3 pb-3">
                        <div className="card-head d-flex justify-content-center">
                          <h6>About</h6>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Introduction</div>
                          <div className="about-des">
                            The following report provides an analysis of a mock
                            interview. The purpose of this report is to assess
                            the candidate's performance during the interview and
                            provide insights into their strengths and areas for
                            improvement.
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Use of Report</div>
                          <div className="about-des">
                            The insights and recommendations presented in this
                            report can help the candidate enhance their skills
                            and increase their chances of success in future job
                            interviews.{" "}
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="que-des">
                            Confidentiality & Disclaimer
                          </div>
                          <div className="about-des">
                            This report is confidential and intended solely for
                            the use of the recipient. It contains information
                            that is proprietary and confidential to the
                            interview candidate and should not be shared with
                            any third party without the candidate's express
                            written consent. The opinions and recommendations
                            presented in this report are subjective and may not
                            be indicative of the candidate's future performance
                            or suitability for a particular job. Furthermore,
                            the report is not a guarantee of the candidate's
                            qualifications or suitability for any specific
                            position or employer. The recipient of this report
                            assumes full responsibility for any actions taken
                            based on its contents.
                          </div>
                        </div>
                      </div>
                      <div className="res-bottom"></div>
                    </div>
                  </div>
                  <div className="container p-0 my-0">
                    <div className="res-box col-12  mb-0">
                      {/* <img src={CardTop} style={{
                  width: "100%"
                }} className="res-top-img" alt="topborder" /> */}
                      <div className="res-bottom"></div>
                      <div className=" px-1 px-sm-3 pb-3">
                        <div className="card-head d-flex justify-content-center">
                          <h6>Interview Parameters</h6>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Professionalism</div>
                          <div className="about-des">
                            Professionalism is an essential factor in an
                            interview. It is important to present oneself in a
                            professional manner to make a positive impression on
                            the interviewer and increase the chances of being
                            selected for the job
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Communication</div>
                          <PurpuleHeading className="my-2">
                            Fluency
                          </PurpuleHeading>
                          <div className="about-des">
                            Evaluating an individual's ability to speak a
                            language fluently. It involves analyzing the
                            individual's speech patterns, including their speed,
                            rhythm, intonation, and pronunciation.
                          </div>
                          <PurpuleHeading className="my-2">
                            Speed
                          </PurpuleHeading>
                          <div className="about-des">
                            It can be evaluated based on factors such as the
                            response time to questions, clarity of
                            communication, and how well the interviewee was able
                            to articulate their thoughts and ideas.
                          </div>
                          <PurpuleHeading className="my-2">
                            Articulation
                          </PurpuleHeading>
                          <div className="about-des">
                            Articulation is an important aspect of communication
                            and is essential for effective interpersonal
                            relationships, academic and professional success,
                            and overall social functioning
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Video</div>
                          <PurpuleHeading className="my-2">
                            Sociability
                          </PurpuleHeading>
                          <div className="about-des">
                            Sociability refers to an individual's capacity to
                            interact and communicate effectively with people. It
                            focuses on verbal- non verbal communication too
                          </div>
                          <PurpuleHeading className="my-2">
                            Facial Expression
                          </PurpuleHeading>
                          <div className="about-des">
                            Facial expressions refer to the various movements
                            and configurations of the face that convey emotional
                            states, attitudes, intentions, and social
                            information to others
                          </div>
                          <PurpuleHeading className="my-2">
                            Energy Level
                          </PurpuleHeading>
                          <div className="about-des">
                            Energy level refers to an individual's overall level
                            of physical, mental, and emotional energy.
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="que-des">Speech</div>
                          <PurpuleHeading className="my-2">Pace</PurpuleHeading>
                          <div className="about-des">
                            Pace refers to the rate or speed at which
                            communication is done. In order to convey the
                            communication it is important to maintain the pace
                            at normal speed
                          </div>
                          <div className="page-break"></div>

                          <PurpuleHeading className="my-2">
                            Clarity
                          </PurpuleHeading>
                          <div className="about-des">
                            Clarity refers to the quality of being clear, easy
                            to understand, and free from ambiguity or confusion.
                            It is an essential aspect of effective communication
                            and is crucial for ensuring that the intended
                            message is conveyed accurately and comprehensively.
                          </div>
                          <PurpuleHeading className="my-2">
                            Sentiments
                          </PurpuleHeading>
                          <div className="about-des">
                            Sentiments refer to the emotional or effective state
                            associated with a particular thought, idea, or
                            experience. It encompasses a wide range of feelings,
                            including happiness, sadness, anger, love, fear, and
                            surprise, among others.
                          </div>
                        </div>
                      </div>
                      <div className="res-bottom"></div>
                    </div>

                    <div className="page-break"></div>
                  </div>
                </>
              )}
              {resultdata.length > 0 && resultdata[0].body !== null && (
                <div className="container my-0 p-0">
                  <div className="res-box col-12  mb-0">
                    {/* <img src={CardTop} style={{
                    width: "100%"
                  }} className="res-top-img" alt="topborder" /> */}
                    <div className="res-bottom"></div>
                    <div className=" px-3 pb-3">
                      <div className="card-head d-flex justify-content-center">
                        <h6>Interview Assesment</h6>
                      </div>
                      <div className="que-des my-2">Overview</div>
                      <div className="d-flex flex-wrap-768 gap-2 ">
                        {/* <div className="col-md-6 col-12 report-box p-2">
                          <h6>Professionalism</h6>
                          <ul className="skill-list">
                            <li className="skill">
                              <h3>
                                Confidence (
                                {(
                                  totalscore?.confidence / resultdata.length
                                ).toFixed(0)}
                                %)
                              </h3>
                              <div
                                className="progress-container"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#eee",
                                  height: "20px",
                                  borderRadius: "20px",
                                  border: "1px solid #6d6d6d",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      totalscore.confidence / resultdata.length
                                    }%`, // Dynamic width
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px 0px 0px 20px",
                                  }}
                                ></div>
                              </div>
                            </li>
                            <li className="skill">
                              <h3>
                                Optimistic (
                                {(
                                  totalscore.optimistic / resultdata.length
                                ).toFixed(0)}
                                %)
                              </h3>
                              <div
                                className="progress-container"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#eee",
                                  height: "20px",
                                  borderRadius: "20px",
                                  border: "1px solid #6d6d6d",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      totalscore.optimistic / resultdata.length
                                    }%`, // Dynamic width
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px 0px 0px 20px",
                                  }}
                                ></div>
                              </div>
                            </li>
                            <li className="skill">
                              <h3>
                                Passion & Enthusiasm (
                                {(
                                  totalscore.passion / resultdata.length
                                ).toFixed(0)}
                                %)
                              </h3>
                              <div
                                className="progress-container"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#eee",
                                  height: "20px",
                                  borderRadius: "20px",
                                  border: "1px solid #6d6d6d",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      totalscore.passion / resultdata.length
                                    }%`, // Dynamic width
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px 0px 0px 20px",
                                  }}
                                ></div>
                              </div>
                            </li>
                            <li className="skill">
                              <h3>
                                Industry Keywords (
                                {(
                                  totalscore.industryKeyword / resultdata.length
                                ).toFixed(0)}
                                %)
                              </h3>
                              <div
                                className="progress-container"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#eee",
                                  height: "20px",
                                  borderRadius: "20px",
                                  border: "1px solid #6d6d6d",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      totalscore.industryKeyword /
                                      resultdata.length
                                    }%`, // Dynamic width
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px 0px 0px 20px",
                                  }}
                                ></div>
                              </div>
                            </li>
                            <li className="skill">
                              <h3>
                                Clarity (
                                {(
                                  totalscore.clarity / resultdata.length
                                ).toFixed(0)}
                                %)
                              </h3>
                              <div
                                className="progress-container"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#eee",
                                  height: "20px",
                                  borderRadius: "20px",
                                  border: "1px solid #6d6d6d",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${
                                      totalscore.clarity / resultdata.length
                                    }%`, // Dynamic width
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px 0px 0px 20px",
                                  }}
                                ></div>
                              </div>
                            </li>
                          </ul>
                        </div> */}
                        <div className="col-md-6 col-12 report-box">
                          <div className="p-2">
                            <p>Statistics</p>
                            <h6>Personality</h6>
                            <div className="w-75">
                              <Doughnut data={data} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="res-detail">
                        <div>
                          <h6>Professionalism</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Confidence </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={(
                                  totalscore?.confidence / resultdata.length
                                ).toFixed(0)}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Optimistic </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={(
                                  totalscore.optimistic / resultdata.length
                                ).toFixed(0)}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Passion & Enthusiasm </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={(
                                  totalscore.passion / resultdata.length
                                ).toFixed(0)}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Industry Keywords </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={(
                                  totalscore.industryKeyword / resultdata.length
                                ).toFixed(0)}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Clarity </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={(
                                  totalscore.clarity / resultdata.length
                                ).toFixed(0)}
                              />
                            </Box>
                          </div>
                        </div>
                        <div>
                          <h6>Communication</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Fluency Report </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={totalscore.fluent / resultdata.length}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Articulation </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={
                                  totalscore.articulation / resultdata.length
                                }
                              />
                            </Box>
                          </div>
                        </div>
                        <div>
                          <h6>Body Language</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Sociability </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={
                                  totalscore.sociability / resultdata.length
                                }
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Facial Expression </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={
                                  totalscore.facialExpression /
                                  resultdata.length
                                }
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Energy Level </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={
                                  totalscore.energyVideo / resultdata.length
                                }
                              />
                            </Box>
                          </div>
                        </div>
                        <div>
                          <h6>Speech</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Pace </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={totalscore.pace / resultdata.length}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Clarity </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={totalscore.clarity / resultdata.length}
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="col-3 mb-0">Sentiments </p>
                          <div className="col-9">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel
                                value={
                                  totalscore.sentiments / resultdata.length
                                }
                              />
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="res-bottom"></div> */}
                    <div className="page-break"></div>
                  </div>
                </div>
              )}
              <div className="container my-0 p-0">
                {resultdata.length > 0 ? (
                  resultdata.map((data, idx) => (
                    <>
                      {data.body === null ||
                      data.body === "Video cannot be parsed!" ? (
                        <ReportCardNull
                          data={data}
                          index={idx}
                          // question={
                          //   userDetail?.interview_id?.interviewquestions[idx]
                          // }
                          question={getQuestion(
                            userDetail?.interview_id?.interviewquestions[idx]
                              ?.question,
                            userDetail?.interviewLanguage || "en"
                          )}
                        />
                      ) : (
                        <ReportCard
                          data={data}
                          index={idx}
                          // question={
                          //   userDetail?.interview_id?.interviewquestions[idx]
                          // }
                          question={getQuestion(
                            userDetail?.interview_id?.interviewquestions[idx]
                              ?.question,
                            userDetail?.interviewLanguage || "en"
                          )}
                        />
                      )}
                      <div className="page-break"></div>
                    </>
                  ))
                ) : (
                  <>
                    <div className="d-flex justify-content-center my-5">
                      <YellowHeading>No reports to Show</YellowHeading>
                    </div>
                  </>
                )}
              </div>
            </BoxStyle2>
          </DashboardBoxStyle>
        </div>
      )}
    </>
  );
};

export default Reports;
